import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

const ZendeskApplication = ({ appConfig }) => {
  const AuthKeyZenDesk = appConfig.zendeskauthkey;
  const showZendeskonGames = appConfig?.showzendeskongames || true;

  useEffect(() => {
    if (!showZendeskonGames || window.location.href.includes('game'))
      return null;

    const scriptTag = document.createElement('script');

    scriptTag.type = 'text/javascript';
    scriptTag.src = `https://static.zdassets.com/ekr/snippet.js?key=${AuthKeyZenDesk}`;
    scriptTag.id = 'ze-snippet';

    scriptTag.onload = () => {
      // Here you can add the code that will be executed after the script is loaded
    };

    document.documentElement.appendChild(scriptTag);

    return () => {
      scriptTag?.remove();
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return <></>;
};

ZendeskApplication.propTypes = {
  appConfig: PropTypes.object,
};

export default ZendeskApplication;
